export default [
  {
    name: 'Administrar documentos, resúmenes, cuadratura y clientes',
    id: 'add_document',
    icon: 'file-document',
    category: 'Emisión',
    active: false,
    parent: 'Emisión',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  // {
  //   name: 'Ver documentos',
  //   id: 'view_document',
  //   icon: 'file-document',
  //   category: 'Emisión',
  //   active: false,
  //   assignedByGroups: [],
  //   countries: ['CL', 'PE']
  // },
  // {
  //   name: 'Crear documentos',
  //   id: 'add_document',
  //   icon: 'file-document',
  //   category: 'Emisión',
  //   active: false,
  //   parent: 'Emisión',
  //   key: 'dtes',
  //   assignedByGroups: [],
  //   countries: ['CL', 'PE']
  // },
  {
    name: 'Ceder documentos', // 'Crear cesión',
    id: 'add_grant',
    icon: 'file-document',
    parent: 'Emisión',
    category: 'Emisión',
    active: false,
    assignedByGroups: [],
    countries: ['CL']
  },
  {
    name: 'Crear proceso de emisión por lote',
    id: 'add_batch',
    icon: 'file-document',
    category: 'Emisión por lotes',
    active: false,
    parent: 'Emisión',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Editar proceso de emisión por lote',
    id: 'change_batch',
    icon: 'file-document',
    parent: 'Emisión',
    active: false,
    category: 'Emisión por lotes',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Eliminar proceso de emisión por lote',
    id: 'delete_batch',
    icon: 'file-document',
    parent: 'Emisión',
    active: false,
    category: 'Emisión por lotes',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver proceso de emisión por lote',
    id: 'view_batch',
    icon: 'file-document',
    parent: 'Emisión',
    active: false,
    category: 'Emisión por lotes',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  // {
  //   name: 'Eliminar log',
  //   id: 'delete_logentry'
  // },
  // {
  //   name: 'Ver log',
  //   id: 'view_logentry'
  // },
  {
    name: 'Cargar certificado digital',
    id: 'add_genericcertificate',
    icon: 'bank',
    category: 'SII',
    active: false,
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  // {
  //   name: 'Reemplazar certificado digital',
  //   id: 'change_certificate',
  //   category: 'sii',
  // assignedByGroups: [],
  // },
  {
    name: 'Ver certificado digital',
    id: 'view_genericcertificate',
    icon: 'bank',
    category: 'SII',
    active: false,
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  // {
  //   name: 'Ver documentos cedidos',
  //   id: 'view_grant'
  // },
  {
    name: 'Cargar folios ',
    id: 'add_caf',
    icon: 'folder',
    category: 'Folios',
    active: false,
    assignedByGroups: [],
    countries: ['CL']
  },
  {
    name: 'Ver folios',
    id: 'view_caf',
    icon: 'folder',
    category: 'Folios',
    active: false,
    assignedByGroups: [],
    countries: ['CL']
  },
  // {
  //   name: 'Can add swap',
  //   id: 'add_swap'
  // },
  // {
  //   name: 'Can view swap',
  //   id: 'view_swap'
  // },
//  {
//     name: 'Responder documento de compra',
//     id: 'add_swapresponse',
//     icon: 'inbox',
//     category: 'Documentos',
//     parent: 'Recepción',
//     active: false,
//     assignedByGroups: [],
//     countries: ['CL']
//   },
//   {
//     name: 'Ver respuesta documento de compra',
//     id: 'view_swapresponse',
//     icon: 'file-document',
//     parent: 'Recepción',
//     category: 'Documentos',
//     countries: ['CL'],
//     assignedByGroups: [],
//     active: false
//   },
  // {
  //   name: 'Can add supplier response',
  //   id: 'add_supplierresponse'
  // },
  // {
  //   name: 'Can view supplier response',
  //   id: 'view_supplierresponse'
  // },
  {
    name: 'Ver documentos de compra',
    id: 'view_purchase',
    icon: 'file-document',
    category: 'Recepción',
    parent: 'Recepción',
    active: false,
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver tienda',
    id: 'view_branch',
    icon: 'store',
    category: 'Tiendas',
    parent: 'Tiendas',
    active: false,
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Editar tienda',
    id: 'change_branch',
    icon: 'store',
    category: 'Tiendas',
    parent: 'Tiendas',
    active: false,
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver POS',
    id: 'view_pos',
    icon: 'store',
    category: 'Tiendas',
    parent: 'Recepción',
    active: false,
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver organización',
    id: 'view_account',
    icon: 'domain',
    category: 'Organización',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Editar organización',
    id: 'change_account',
    icon: 'domain',
    category: 'Organización',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver invitaciones de usuarios',
    id: 'view_invitation',
    icon: 'account',
    category: 'Usuarios',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Enviar invitaciones de usuarios',
    id: 'add_invitation',
    icon: 'account',
    category: 'Usuarios',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  // {
  //   name: 'Editar invitaciones de usuarios',
  //   id: 'change_invitation'
  // },
  {
    name: 'Eliminar invitaciones de usuarios',
    id: 'delete_invitation',
    icon: 'account',
    category: 'Usuarios',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  // {
  //   name: 'Crear miembro',
  //   id: 'add_member'
  // },
  {
    name: 'Editar usuarios',
    id: 'change_member',
    icon: 'account',
    category: 'Usuarios',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver usuarios',
    id: 'view_member',
    icon: 'account',
    category: 'Usuarios',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Eliminar usuarios',
    id: 'delete_member',
    icon: 'account',
    category: 'Usuarios',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Crear roles de usuarios',
    id: 'add_group',
    icon: 'account-group',
    category: 'Roles',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Eliminar rol de usuario',
    id: 'delete_group',
    icon: 'account-group',
    category: 'Roles',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver roles de usuarios',
    id: 'view_group',
    icon: 'account-group',
    category: 'Roles',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Actualizar API Key',
    id: 'change_token',
    icon: 'xml',
    category: 'Developers',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver API Key',
    id: 'view_setupkey',
    icon: 'xml',
    category: 'Developers',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver webhook',
    id: 'view_webhook',
    icon: 'xml',
    category: 'Developers',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Crear webhook',
    id: 'add_webhook',
    icon: 'xml',
    category: 'Developers',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  }
  // {
  //   name: 'Crear notificaciones',
  //   id: 'add_notification',
  //   icon: 'bell',
  //   category: 'Notificaciones',
  //   active: false,
  //   parent: 'Configuraciones',
  //   assignedByGroups: [],
  //   countries: ['CL', 'PE']
  // },
  // {
  //   name: 'Ver notificaciones',
  //   id: 'view_notification',
  //   icon: 'bell',
  //   category: 'Notificaciones',
  //   active: false,
  //   parent: 'Configuraciones',
  //   assignedByGroups: [],
  //   countries: ['CL', 'PE']
  // },
  // {
  //   name: 'Editar notificaciones',
  //   id: 'change_notification',
  //   icon: 'bell',
  //   category: 'Notificaciones',
  //   active: false,
  //   parent: 'Configuraciones',
  //   assignedByGroups: [],
  //   countries: ['CL', 'PE']
  // }
  // {
  //   name: 'Ver contribuyente',
  //   id: 'view_taxpayer'
  // },
  // {
  //   name: 'Can add bank',
  //   id: 'add_bank'
  // },
  // {
  //   name: 'Can change bank',
  //   id: 'change_bank'
  // },
  // {
  //   name: 'Can delete bank',
  //   id: 'delete_bank'
  // },
  // {
  //   name: 'Can view bank',
  //   id: 'view_bank'
  // },
  // {
  //   name: 'Can add customer',
  //   id: 'add_customer'
  // },
  // {
  //   name: 'Can change customer',
  //   id: 'change_customer'
  // },
  // {
  //   name: 'Can delete customer',
  //   id: 'delete_customer'
  // },
  // {
  //   name: 'Can view customer',
  //   id: 'view_customer'
  // },
  // {
  //   name: 'Editar tienda',
  //   id: 'change_branch'
  // },
  // {
  //   name: 'Ver tienda',
  //   id: 'view_branch'
  // },
  // {
  //   name: 'Editar  POS',
  //   id: 'change_pos'
  // },
  // {
  //   name: 'Ver POS',
  //   id: 'view_pos'
  // },
  // {
  //   name: 'Can add message',
  //   id: 'add_message'
  // },
  // {
  //   name: 'Can view message',
  //   id: 'view_message'
  // },
  // {
  //   name: 'Crear un proveedor',
  //   id: 'add_supplier'
  // },
  // {
  //   name: 'Editar un proveedor',
  //   id: 'change_supplier'
  // },
  // {
  //   name: 'Eliminar un proveedor',
  //   id: 'delete_supplier'
  // },
  // {
  //   name: 'Ver proveedores',
  //   id: 'view_supplier'
  // },
  // {
  //   name: 'Ver eventos',
  //   id: 'view_event'
  // },
  // {
  //   name: 'Can add webhook',
  //   id: 'add_webhook'
  // },
  // {
  //   name: 'Can change webhook',
  //   id: 'change_webhook'
  // },
  // {
  //   name: 'Can delete webhook',
  //   id: 'delete_webhook'
  // },
  // {
  //   name: 'Can view webhook',
  //   id: 'view_webhook'
  // },
  // {
  //   name: 'Can view conversation',
  //   id: 'view_conversation'
  // },
  // {
  //   name: 'Editar configuracionesuración de la empresa',
  //   id: 'change_irsconnection'
  // },
  // {
  //   name: 'Ver configuracionesuración de la empresa',
  //   id: 'view_irsconnection'
  // },
  // {
  //   name: 'Crear plantillas PDF',
  //   id: 'add_pdftemplate'
  // },
  // {
  //   name: 'Editar plantillas PDF',
  //   id: 'change_pdftemplate'
  // },
  // {
  //   name: 'Eliminar plantillas PDF',
  //   id: 'delete_pdftemplate'
  // },
  // {
  //   name: 'Ver plantillas PDF',
  //   id: 'view_pdftemplate'
  // },
  // {
  //   name: 'Can change Setup key',
  //   id: 'change_setupkey'
  // },
  // {
  //   name: 'Can view Setup key',
  //   id: 'view_setupkey'
  // },
  // {
  //   name: 'Crear Certificado SUNAT',
  //   id: 'add_sunatcertificate'
  // },
  // {
  //   name: 'Reemplazar Certificado SUNAT',
  //   id: 'change_sunatcertificate'
  // },
  // {
  //   name: 'Ver Certificado SUNAT',
  //   id: 'view_sunatcertificate'
  // },
  // {
  //   name: 'Editar configuracionesuración del POS',
  //   id: 'change_posaccountsetting'
  // },
  // {
  //   name: 'Ver configuracionesuaración del POS',
  //   id: 'view_posaccountsetting'
  // },
  // {
  //   name: 'Ver eventos del POS',
  //   id: 'view_eventpos'
  // }
]